import { addDoc, collection, doc, getDocs, setDoc, updateDoc } from 'firebase/firestore';

import { firebaseAuth, firebaseDB } from '../../components/firebase/firebase';
import { aiAgentUrl } from '../../constants/Url';
import Request from '../../Services/Request';
import { sleep } from '../../utils/miscUtils';
import { setChatLoading } from '../mixl/action';
import { RootState } from '../root-reducer';
import { chatActionTypes, IMessageItem } from './types';

const { PostDataCustomUrl } = Request();

export const hideUserIdentity = (hideIdentity: boolean) => ({
  type: chatActionTypes.HIDE_USER_IDENTITY,
  hideIdentity
});

export const startRolePlayChat = () => async (dispatch, getState) => {
  const store = getState() as RootState;
  const { coachMode } = store.web3NetworkData;
  try {
    const userId = firebaseAuth.currentUser?.uid;
    const data = await PostDataCustomUrl(`${aiAgentUrl}/create_scenario`, { userId, coachMode });
    const { scenario_text, simulated_user, simulated_user_question } = data;
    console.log('startRolePlayChat -> data', data);
    const chatRef = getChatThreadCollection();
    const rolePlayChatRef = doc(chatRef);
    const timestamp = Date.now();
    const rolePlayMessage: IMessageItem = {
      username: 'Mixlearn',
      avatar: '',
      timestamp,
      message: scenario_text,
      threadId: rolePlayChatRef.id,
      replyCount: 0,
      isPrivate: true,
      userRole: 'model',
      attchments: {
        attachmentType: 'role_play_scenario'
      }
    };
    console.log('rolePlayMessage', rolePlayMessage, rolePlayChatRef.path);
    const scenarioRef = collection(firebaseDB, 'role_play_scene');
    await addDoc(scenarioRef, {
      userId,
      scenario_text,
      timestamp,
      simulated_user: {
        username: simulated_user.username,
        avatar: simulated_user.avatar,
        question: simulated_user_question
      },
      chatThread: rolePlayChatRef.path
    });

    await setDoc(rolePlayChatRef, rolePlayMessage);
    dispatch(setSelectedChatThread({ ...rolePlayMessage }));
    dispatch(setChatLoading(true));
    await sleep(1000);
    const simulatedUserMessage: IMessageItem = {
      userRole: 'simulated_user',
      message: simulated_user_question,
      timestamp: Date.now(),
      isPrivate: true,
      username: `${simulated_user.username} (Simulated)`,
      avatar: simulated_user.avatar
    };
    const docRef = doc(collection(rolePlayChatRef, 'chat_messages'));
    console.log('simulatedUserMessage', simulatedUserMessage, docRef.path);
    await setDoc(docRef, simulatedUserMessage);
    dispatch(setChatLoading(false));
    await updateDoc(rolePlayChatRef, { replyCount: 1, replyAvatars: [simulated_user.avatar] });
    console.log('simulatedUserMessage', simulatedUserMessage);
  } catch (error) {
    console.error('startRolePlayChat -> error', error);
  }
};
export const setSelectedChatThread = (messageItem: IMessageItem) => (dispatch) => {
  const threadRef = getChatMessagesCollection(messageItem);
  const { threadId } = messageItem;
  getDocs(threadRef).then((snapshot) => {
    if (snapshot.empty) {
      dispatch({
        type: chatActionTypes.SET_SELECTED_CHAT_THREAD,
        payload: {
          threadMessages: [messageItem],
          threadId
        }
      });
      return;
    }
    const threadMessages = snapshot.docs.map((doc) => doc.data());
    const sortedThreadMessages = [...threadMessages, messageItem].sort(
      (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );
    dispatch({
      type: chatActionTypes.SET_SELECTED_CHAT_THREAD,
      payload: {
        threadMessages: sortedThreadMessages,
        threadId
      }
    });
  });
};

export const updateCurrenChatThread = (newMessages) => (dispatch, getState) => {
  const { chatData } = getState();
  const currentThread = chatData.currentChatThread;
  console.log('updateCurrenChatThread -> chatData', chatData, newMessages);
  if (!currentThread) {
    return;
  }

  const { currentChatThread } = chatData;
  const mainThreadMessage = currentChatThread.threadMessages.filter((message) => message.threadId);
  const updatedThread = {
    threadMessages: [...mainThreadMessage, ...newMessages],
    threadId: currentThread.threadId
  };
  dispatch({
    type: chatActionTypes.SET_SELECTED_CHAT_THREAD,
    payload: { ...updatedThread }
  });
};

export const clearSelectedChatThread = () => async (dispatch) => {
  const threadContainer = document.getElementsByClassName('thread_view')[0];
  if (threadContainer) {
    threadContainer.style.right = '-100%';
    await sleep(500);
  }
  dispatch({
    type: chatActionTypes.SET_SELECTED_CHAT_THREAD,
    payload: null
  });
};

export function getChatMessagesCollection(messageItem: IMessageItem) {
  const { threadId, isPrivate } = messageItem;
  if (isPrivate) {
    const uid = firebaseAuth.currentUser?.uid;
    return collection(firebaseDB, 'glo-users', uid, 'mixl_chat', threadId, 'chat_messages');
  }
  return collection(firebaseDB, 'mixl_chat', threadId, 'chat_messages');
}

export function getChatThreadCollection() {
  const uid = firebaseAuth.currentUser?.uid;
  const mixlChatCollection = collection(firebaseDB, 'glo-users', uid, 'mixl_chat');
  return mixlChatCollection;
}
