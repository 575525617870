import { collection, deleteDoc, doc, getDocs, setDoc } from 'firebase/firestore';
import { v4 as generateId } from 'uuid';

import { firebaseAuth, firebaseDB, getUserToken } from '../../components/firebase/firebase';
import { aiAgentUrl, liveServerName, loadMeetingLibrary } from '../../constants/Url';
import Request from '../../Services/Request';
import { sleep } from '../../utils/miscUtils';
import { isDesktopApp } from '../../utils/platformUtils';
import { updatePlatformError } from '../device/action';
import {
  addChatMessage,
  clearChatHistory,
  editMixlTask,
  loadTrainingOutlines,
  setChatLoading,
  setSelectedTrainingOutline
} from '../mixl/action';
import { RootState } from '../root-reducer';
import { convertToGsUrl, generateQuizQuestions, getStudyNotesLinks, getStudyRoomName } from './functions';
import { INoteData, ISkillStat, IStydySession, studyActionTypes } from './types';

const { PostSecuredData, PostDataCustomUrl } = Request();

export const startAnalysingNotes = () => (dispatch) => {
  dispatch({
    type: studyActionTypes.START_PROCESSING_NOTES
  });
};

export const deleteSkillStat = (skillStat: ISkillStat) => async (dispatch, getState) => {
  const store = getState() as RootState;
  const { skillStats } = store.studyData;
  const currentSkills = [...skillStats];
  const updatedSkills = currentSkills.filter((skill) => skill.name !== skillStat.name);
  const userId = firebaseAuth.currentUser?.uid;
  const docRef = doc(firebaseDB, 'glo-users', userId, 'skill_stats', skillStat.name);
  await deleteDoc(docRef);
  dispatch(updateStudySkillsStats(updatedSkills));
};

export const updateStudySkillsStats = (stats) => (dispatch) => {
  if (stats) {
    dispatch({
      skillStats: [...stats],
      type: studyActionTypes.UPDATE_SKILLS_STATS
    });
    return;
  }
  const userId = firebaseAuth.currentUser?.uid;
  if (!userId) {
    return;
  }
  const colRef = collection(firebaseDB, 'glo-users', userId, 'skill_stats');
  getDocs(colRef)
    .then(async (snapshot) => {
      const skillStats: ISkillStat[] = await Promise.all(
        snapshot.docs.map((doc) => {
          const data = doc.data();
          return { ...data };
        })
      );
      skillStats.push({ name: 'mixlearn', quizzes: 0, level: 'Beginner', progress: 0, hours: 0 });
      if (!skillStats.length) {
        return;
      }
      dispatch({
        skillStats,
        type: studyActionTypes.UPDATE_SKILLS_STATS
      });
    })
    .catch((err) => console.log('SkillStats: err ', err));
};

export const stopAnalysingNotes = () => (dispatch) => {
  dispatch({
    type: studyActionTypes.STOP_PROCESSING_NOTES
  });
};
// prettier-ignore
export const processStudyNotes = (studyNotes: INoteData[], startSession: () => void, failureCallBack: () => void) => async (dispatch, getState) => {
  if (studyNotes?.length > 0) {
    let didStartSession = false;
    for await (const noteData of studyNotes) {
      const { quizData, quizTopic } = await generateQuizQuestions(noteData);
      if (quizData?.length > 0) {
        noteData['quizTopic'] = quizTopic;
        dispatch(updateQuizData(quizData, [noteData]));
        if (!didStartSession) {
          didStartSession = true;
          startSession();
        }
      }
    }
  }

  const quizData = getState().studyData.quizData;
  if (!quizData?.length) {
    console.log('err: no quiz data available');
    if (failureCallBack !== null) {
      await failureCallBack();
    }
    dispatch(updatePlatformError('Something went wrong. Please try again later'));
  }

  dispatch(stopAnalysingNotes());
};

export const updateQuizAnswer = (answer: string, quizId: string) => (dispatch, getState) => {
  const store = getState() as RootState;
  const newQuizData = [...store.studyData.quizData];
  const quiz = newQuizData.find((item) => item.id === quizId);
  if (quiz) {
    quiz['selectedAnswer'] = answer;
  }
  dispatch({ type: studyActionTypes.UPDATE_QUIZ_DATA, quizData: [...newQuizData] });
};

export const resetStudySession = () => async (dispatch) => {
  dispatch({
    type: studyActionTypes.RESET_SESSION_DATA
  });
};

export const isLoadingStudyRoom = (isLoading) => {
  return { type: studyActionTypes.UPDATE_LOADING_ROOM, isLoading };
};

export const showSessionHistroyModal = (show) => {
  return { type: studyActionTypes.SHOULD_SHOW_SESSION_HISTORY, show };
};
export const setShowUploadModal = (show) => {
  return { type: studyActionTypes.SHOULD_SHOW_UPLOAD_MODAL, show };
};

export const initialseStudyRoom = () => (dispatch, getState) => {
  const parentNode = document.getElementById('meeting_container');
  if (!parentNode) {
    return;
  }
  const roomName = getStudyRoomName(getState);
  if (!roomName) {
    return;
  }
  const JitsiMeetExternalAPI = window.JitsiMeetExternalAPI;
  // prettier-ignore
  const mixlLiveApi = new JitsiMeetExternalAPI(liveServerName, {
    width: '100%', height: '100%', roomName, parentNode, configOverwrite: {
      prejoinConfig: { enabled: false }, disableModeratorIndicator: true, hideDisplayName: true,
      hideConferenceSubject: true, toolbarButtons: [], notifications: [], remoteVideoMenu: {
        disablePrivateChat: true, disableDemote: true, disableGrantModerator: true,
      }, defaultRemoteDisplayName: 'Mixlearn User', enableInsecureRoomNameWarning: false,
      hideRecordingLabel: true, filmstrip: { disableResizable: true }, participantsPane: {
        hideModeratorSettingsTab: true, hideMoreActionsButton: true, hideMuteAllButton: true
      }, deeplinking: { disabled: true }, disableLocalVideoFlip: true, disableRemoteMute: true,
      enableNoAudioDetection: false, analytics: { disabled: true }, e2ee: { disabled: true },
      maxFullResolutionParticipants: -1, enableNoisyMicDetection: false, legalUrls: {
        privacy: 'https://app.mixl.ai/privacypolicy', terms: 'https://app.mixl.ai/termsofservice',
        helpCentre: 'https://www.mixl.ai/#community'
      }, dynamicBrandingUrl: 'https://app.mixl.ai/orm/community/live_config'
    }, interfaceConfigOverwrite: {
      SUPPORT_URL: 'https://www.mixl.ai/#community', DEFAULT_REMOTE_DISPLAY_NAME: 'Mixlearn User',
      PROVIDER_NAME: 'Mixl', SHOW_JITSI_WATERMARK: false, DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
      MOBILE_APP_PROMO: false, AUTO_PIN_LATEST_SCREEN_SHARE: true, DISABLE_VIDEO_BACKGROUND: true,
      APP_NAME: 'Mixl Learn', VIDEO_QUALITY_LABEL_DISABLED: true, VERTICAL_FILMSTRIP: false,
    }
  });
  window.mixlLiveApi = mixlLiveApi;

  return mixlLiveApi;
};

export const maybeInviteAdmin = () => (dispatch, getState) => {
  const roomName = getStudyRoomName(getState);
  const studyNotes = getStudyNotesLinks(getState);

  getUserToken().then((token) => {
    if (token) {
      PostSecuredData('community/live_invite', { roomName, studyNotes }, `Bearer ${token}`).catch((err) => {
        console.log('Err: unable to send invite', err);
      });
    }
  });
};

export const startLiveSession = () => async (dispatch) => {
  dispatch(isLoadingStudyRoom(true));
  const meetingModule = await loadMeetingLibrary();
  if (!meetingModule) {
    dispatch(updatePlatformError('Unable to start session'));
    dispatch(isLoadingStudyRoom(false));
    return;
  }
  // const roomName = `mixl_live_${Date.now()}`;
  const roomName = `mixl_live_tutor`;
  const roomInfo = { roomName };
  if (isDesktopApp) {
    window.CapacitorCustomPlatform.startLiveSession();
  }
  dispatch({ type: studyActionTypes.START_LIVE_SESSION, roomInfo });
};

export const stopLiveSession = () => (dispatch) => {
  if (isDesktopApp) {
    window.CapacitorCustomPlatform.stopLiveSession();
  }
  dispatch({ type: studyActionTypes.STOP_LIVE_SESSION });
};

export const setReviewActive = (payload) => async (dispatch, getState) => {
  dispatch({
    type: studyActionTypes.SET_REVIEW_ACTIVE,
    payload
  });
  if (payload && payload === true) {
    const store = getState() as RootState;
    const newQuizData = [...store.studyData.quizData];
    dispatch(AnalyzeCurrentQuestion(newQuizData[0]?.id));
  }
};

export const AnalyzeCurrentQuestion = (quizId: string, quizIndex) => async (dispatch, getState) => {
  const store = getState() as RootState;
  dispatch(clearChatHistory(true));
  await sleep(200);

  const newQuizData = [...store.studyData.quizData];
  const quiz = newQuizData.find((item, index) => (quizId ? item.id === quizId : quizIndex === index));

  if (quiz) {
    dispatch({ type: studyActionTypes.SET_ACTIVE_QUESTION, quiz });
    if (quiz['selectedAnswer'] === quiz.answer) {
      dispatch(setChatLoading(false));
      const response = { text: 'Great Job! You got this right', isBot: true, shouldGlow: true };
      dispatch(addChatMessage(response, { role: 'model', parts: [{ text: response.text }] }));
    } else {
      const response = { text: 'Oops! You got this wrong', isBot: true, shouldGlow: true };
      dispatch(addChatMessage(response, { role: 'model', parts: [{ text: response.text }] }));
    }

    await sleep(2000);
    dispatch(setChatLoading(true));
    const response = { text: quiz?.explanation, isBot: true };
    dispatch(addChatMessage(response, { role: 'model', parts: [{ text: response.text }] }));
    dispatch(setChatLoading(false));
  }
};
export const analyzeStudyOutline = () => async (dispatch) => {
  // dispatch(clearChatHistory(true));
  // await sleep(200);
  dispatch(setChatLoading(true));
  const response1 = {
    text: `Ask me questions to learn faster. `,
    isBot: true,
    shouldGlow: true
  };
  dispatch(addChatMessage(response1, { role: 'model', parts: [{ text: response1.text }] }));
  dispatch(setChatLoading(false));
};

export const updateQuizData = (quizData: INoteData[], noteData: INoteData[]) => (dispatch) => {
  dispatch({ type: studyActionTypes.ADD_QUIZ_DATA, quizData, noteData });
};

export const shouldShowResultScreen = (show) => (dispatch) => {
  dispatch({ type: studyActionTypes.SHOW_RESULT_SCREEN, show });
};
export const updateDailyQuizId = (quizId: string) => (dispatch) => {
  dispatch({ type: studyActionTypes.UPDATE_DAILY_QUIZ_ID, quizId });
};

export const updateStudySessionData = (sessions: IStydySession[]) => async (dispatch, getState) => {
  const store = getState() as RootState;
  const { skillStats } = store.studyData;
  const currentSkills = [...skillStats];

  const updatedSkills = await Promise.all(
    currentSkills.map((skill) => {
      const sessionList = sessions.filter((session) => {
        const sessionCatrgory = session?.category || [];
        if (!sessionCatrgory || !sessionCatrgory.length) {
          return false;
        }
        const hasSkill = session.category?.includes(skill.name);
        return hasSkill;
      });
      if (!sessionList.length) {
        return skill;
      }
      return { ...skill, sessionList: [...sessionList] };
    })
  );

  // add other category
  const includeOther = sessions.filter((item) => item.category?.includes('other'));
  const hasOther = updatedSkills.find((item) => item.name === 'other');
  if (includeOther.length && !hasOther) {
    updatedSkills.push({
      name: 'other',
      quizzes: 0,
      sessionList: [...includeOther],
      level: 'Beginner',
      progress: 0,
      hours: 0
    });
  }
  // filter empty skills
  // const filteredSkills = updatedSkills.filter((item) => item.sessionList?.length);

  dispatch(updateStudySkillsStats([...updatedSkills]));
  dispatch({ type: studyActionTypes.SET_SESSION_LIST_DATA, sessions });
};

export const createSessionOutline = () => async (dispatch, getState) => {
  const store = getState() as RootState;
  const { studyNotes } = store.studyData;
  dispatch(initializeSkillStats());

  dispatch(startAnalysingNotes());
  const fileUrl = convertToGsUrl(studyNotes[0]);
  const payload = { fileUrl };
  let outline = [];
  const defaultOutline = [
    {
      outlineTitle: studyNotes[0]?.title,
      pageNumber: 1,
      summary: 'No summary available',
      outlineId: generateId()
    }
  ];
  try {
    const { courseOutline } = await PostDataCustomUrl(`${aiAgentUrl}/create_outline`, payload);
    if (!courseOutline || (Array.isArray(courseOutline) && courseOutline?.length === 0)) {
      outline = defaultOutline;
    } else {
      outline = courseOutline;
    }
  } catch (error) {
    outline = defaultOutline;
  }
  outline = outline.map((item) => ({ ...item, outlineId: generateId() }));
  dispatch(loadTrainingOutlines(outline));
  if (outline && outline?.length) {
    const firstOutline = outline[0];
    dispatch(setSelectedTrainingOutline(firstOutline));
  }
  const { selectedTaskClickup } = store.mixlData;
  if (selectedTaskClickup) {
    const taskPayload = { ...selectedTaskClickup, studySessionOutline: outline, studyNotes };
    dispatch(editMixlTask(taskPayload, true));
  }

  dispatch(stopAnalysingNotes());
};
export const setSelectedHistory = (session: IStydySession) => (dispatch) => {
  dispatch({ type: studyActionTypes.SET_SELECTED_HISTORY, session });
};

const initializeSkillStats = () => async (dispatch, getState) => {
  const store = getState() as RootState;
  const { skillStats, studyNotes } = store.studyData;
  const userId = firebaseAuth.currentUser?.uid;
  await Promise.allSettled(
    studyNotes.map(async (note) => {
      const { quizTopic } = note;
      const skill = skillStats.find((item) => item.name === quizTopic);
      if (!skill) {
        const docRef = doc(firebaseDB, 'glo-users', userId, 'skill_stats', quizTopic);
        const newSkill = { name: quizTopic, quizzes: 0, level: 'Beginner', progress: 0, hours: 0 };
        console.log('initializeSkillStats newSkill: ', newSkill);
        await setDoc(docRef, newSkill, { merge: true });
        const updatedSkills = [...skillStats, newSkill];
        dispatch(updateStudySkillsStats(updatedSkills));
      }
    })
  );
};
