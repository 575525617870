import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth, getIdToken, signOut } from 'firebase/auth';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getStorage } from 'firebase/storage';

import { isCapacitor, isDesktopApp } from '../../utils/platformUtils';

// DO NOT TOUCH THIS! USE `.env` TO CHANGE VARIABLS VALUE
// Initialize Firebase
const config = {
  apiKey: import.meta.env.VITE_firebaseApiKey,
  authDomain: import.meta.env.VITE_firebaseAuthDomain,
  databaseURL: import.meta.env.VITE_firebaseDatabaseURL,
  projectId: import.meta.env.VITE_firebaseProjectId,
  storageBucket: import.meta.env.VITE_firebaseStorageBucket,
  messagingSenderId: import.meta.env.VITE_firebaseMessagingSenderId,
  appId: import.meta.env.VITE_firebaseAppId,
  measurementId: import.meta.env.VITE_firebaseMeasurementId
};

if (import.meta.env.DEV) {
  console.log('firebase config ');
}

const firebaseApp = initializeApp(config);
const firebaseDB = getFirestore(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);
const firebaseStorage = getStorage(firebaseApp);
const fireAnalytics = getAnalytics(firebaseApp);
const fireMessaging = getMessaging(firebaseApp);

if (import.meta.env.MODE === 'development') {
  window.firebaseAuth = firebaseAuth;
}

const requestWebNotificationPermission = async () => {
  try {
    const permisssion = await Notification.requestPermission();
    if (permisssion !== 'granted') {
      throw new Error('permission-denied');
    }
    const token = await getToken(fireMessaging, { vapidKey: import.meta.env.VITE_vapidKey });
    if (token) {
      console.log('FCMToken: token', token);
      const currentUser = firebaseAuth.currentUser;
      const userRef = doc(firebaseDB, 'glo-users', currentUser.uid);
      await setDoc(userRef, { webToken: token }, { merge: true });
      return token;
    }
  } catch (error) {
    console.error('Permission denied or error occurred:', error);
  }
};

const subscribeForegroundNotification = () => (_dispatch) => {
  onMessage(fireMessaging, (payload) => {
    console.log('FCMToken: Message received. ', payload);
  });
};

const logoutEveryWhere = async () => {
  if (isCapacitor) {
    const result = await FirebaseAuthentication.getCurrentUser();
    if (result?.user) {
      await FirebaseAuthentication.signOut();
    }
  }
  const currentUser = firebaseAuth.currentUser;
  if (currentUser) {
    await signOut(firebaseAuth);
  }
};

const getUserToken = async () => {
  try {
    const currentUser = firebaseAuth.currentUser;
    if (!currentUser) {
      throw new Error('user-not-found');
    }
    const token = await getIdToken(currentUser);
    return token;
  } catch (error) {
    console.log('token error', error);
    return '';
  }
};

const recordAnalytics = (eventName) => {
  if (window.isNewUser) {
    if (isCapacitor) {
      FirebaseAnalytics.logEvent({ name: `new_${eventName}` });
      return;
    }
    logEvent(fireAnalytics, `new_${eventName}`);
    return;
  }
  if (window.withNotification) {
    if (isCapacitor) {
      FirebaseAnalytics.logEvent({ name: `noti_${eventName}` });
      return;
    }
    logEvent(fireAnalytics, `noti_${eventName}`);
    return;
  }
  if (isCapacitor) {
    FirebaseAnalytics.logEvent({ name: eventName });
    return;
  }
  logEvent(fireAnalytics, eventName);
};

async function logoutWithCleanup(reason?: string) {
  if (!reason) {
    recordAnalytics('userDidLogout');
  }
  await logoutEveryWhere();
  localStorage.clear();
  sessionStorage.clear();
  if (isDesktopApp) {
    await window.CapacitorCustomPlatform.logoutUser();
    return;
  }
  // todo: use react router, sometimes extension will crash, cause x-frame sameorigin issue.
  window.location.replace('/connect');
}

export {
  requestWebNotificationPermission,
  subscribeForegroundNotification,
  fireMessaging,
  firebaseAuth,
  firebaseDB,
  logoutEveryWhere,
  getUserToken,
  firebaseStorage,
  recordAnalytics,
  logoutWithCleanup
};
