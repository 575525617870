import { combineReducers } from 'redux';

import { mixlReviewReducer } from '../views/MixlReview/reducer';
import { businessReducer } from './business/reducer';
import { chatReducer } from './chat/reducer';
import { drawerReducer } from './device/reducer';
import { mixlReducer } from './mixl/reducer';
import { feedPlayerReducer } from './player/reducer';
import { statsReducer } from './stats/reducer';
import { studyReducer } from './study/reducer';
import { StyleReducer } from './style/reducers';
import { web3Reducer } from './webEthereum/reducer';

export const rootReducer = combineReducers({
  chatData: chatReducer,
  studyData: studyReducer,
  web3NetworkData: web3Reducer,
  mixlData: mixlReducer,
  styleData: StyleReducer,
  businessData: businessReducer,
  drawerData: drawerReducer,
  appReviewData: mixlReviewReducer,
  feedPlayerData: feedPlayerReducer,
  statsData: statsReducer
});

export type RootState = ReturnType<typeof rootReducer>;
