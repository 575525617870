import { INoteData, IStudyResult } from '../study/types';

export const chatActionTypes = {
  SET_SELECTED_CHAT_THREAD: 'SET_SELECTED_CHAT_THREAD',
  HIDE_USER_IDENTITY: 'HIDE_USER_IDENTITY'
};

export type IChatThread = {
  threadMessages: IMessageItem[];
  threadId: string;
};

export type IMessageItem = {
  username: string;
  avatar: string;
  timestamp: string | number;
  message: string;
  isPinned?: boolean;
  threadId?: string;
  replyCount?: number;
  isPrivate?: boolean;
  userRole: 'user' | 'model' | 'expert' | 'simulated_user';
  userId?: string;
  hideIdentity?: boolean;
  attchments?: {
    attachmentType?: string;
    questionThread?: string;
    quizResult?: IStudyResult;
    studyNotes?: INoteData[];
    actionType?: string;
    userActions?: Array<{
      actionType: string;
      actionText: string;
    }>;

    expertAnswerBlocks?: {
      introText: string;
      expertAnswer: string;
      userId?: string;
      voteStatus?: 'upvote' | 'downvote';
    };
  };
};

export type IChatState = {
  hideIdentity: boolean;
  currentChatThread: IChatThread | null;
};
