import { chatActionTypes, IChatState } from './types';

const initialChatState: IChatState = {
  hideIdentity: false,
  currentChatThread: null
};

export const chatReducer = (state = initialChatState, action): IChatState => {
  switch (action.type) {
    case chatActionTypes.SET_SELECTED_CHAT_THREAD:
      return {
        ...state,
        currentChatThread: action.payload
      };
    case chatActionTypes.HIDE_USER_IDENTITY:
      return {
        ...state,
        hideIdentity: action.hideIdentity
      };
    default:
      return state;
  }
};
