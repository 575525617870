import { IQuizData } from '../../../redux/study/types';

function calculateQuizResult(quizData: IQuizData[]) {
  const result = { correctAnswersPercentage: 0, incorrectAnswersPercentage: 0, correctAnswersCount: 0 };
  if (!quizData || !quizData.length) {
    return result;
  }
  let correctAnswersCount = 0;

  quizData.map((item) => {
    const { answer, selectedAnswer } = item;
    if (answer === selectedAnswer) {
      correctAnswersCount = correctAnswersCount + 1;
    }
  });
  const correctAnswersPercentage = Math.floor((correctAnswersCount / quizData?.length) * 100);
  result.correctAnswersPercentage = correctAnswersPercentage;
  result.incorrectAnswersPercentage = 100 - correctAnswersPercentage;
  result.correctAnswersCount = correctAnswersCount;
  return result;
}

export { calculateQuizResult };
